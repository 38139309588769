<script>
export default {
	name: 'StatusBlock',
	props: {
		count: {
			type: Number,
			required: true,
			default: () => 0
		}
	}
}
</script>
>

<template>
	<span class="status-block text-primary">
		<div><slot /></div>
		<div class="text-dark">
			{{ count }}
		</div>
	</span>
</template>

<style lang="scss" scoped>
.status-block {
	border-radius: 10px;
	border: 2px solid currentColor;
	width: 150px;
	height: 100px;
	flex-direction: column;
	justify-content: center;
	display: inline-flex;
	margin-left: 25px;
	margin-right: 25px;
	margin-top: 30px;
	margin-bottom: 50px;
	align-items: center;
	> div {
		font-size: 25px;
	}
}
</style>
