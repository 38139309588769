<script>
import CommonTable from '@/components/CommonTable.vue'
import FormFilter from './FormFilter/index.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Layout from '@/components/Layout.vue'
import _ from 'lodash'
import moment from 'moment-timezone'

export default {
	components: { FormFilter, CommonTable, Layout },
	name: 'ShiftDashboard',
	computed: {
		...mapState('shiftDashboard', [
			'data',
			'total',
			'totalPage',
			'filter',
			'selectedShiftRegistrations'
		]),
		...mapGetters('shiftDashboard', ['locationList']),
		columns() {
			return [
				{
					name: 'date',
					field: 'date',
					label: 'Date',
					format: (_val, row) =>
						this.getShiftDate(
							row.shift.shiftStartAt,
							row.shift.locationId
						),
					align: 'left'
				},
				{
					name: 'shifts',
					field: 'shifts',
					label: 'Shifts',
					format: (_val, row) =>
						this.getShiftTime(
							row.shift.shiftStartAt,
							row.shift.shiftEndAt,
							row.shift.locationId
						),
					align: 'left'
				},
				{
					name: 'driverId',
					label: 'Driver ID',
					field: 'driverId',
					format: (_val, row) => _.get(row, 'user.id', 'N/A'),
					align: 'left'
				},
				{
					name: 'name',
					field: 'name',
					label: 'Name',
					format: (_val, row) => _.get(row, 'user.info.name', 'N/A'),
					align: 'left'
				},
				{
					name: 'phoneNumber',
					field: 'phoneNumber',
					label: 'Phone Number',
					format: (_val, row) => {
						const driverAlias = _.get(row, 'user.aliases', [])
						const driverContact = _.find(driverAlias, {
							type: 'PHONE'
						})
						return _.get(driverContact, 'value', 'N/A')
					},
					align: 'left'
				},
				{
					name: 'district',
					field: 'name',
					label: 'District',
					align: 'left'
				},
				{
					name: 'status',
					field: 'status',
					label: 'Status',
					align: 'left'
				},
				{
					name: 'actions',
					label: 'Actions',
					align: 'center'
				}
			]
		}
	},
	async created() {
		await this.getLocationIdList()
		await this.getDashboard({ page: 1, limit: 100 })
	},
	methods: {
		...mapActions('shiftDashboard', [
			'getShiftDashboard',
			'getLocationIdList',
			'updateSelectedShiftRegistrations',
			'approveShiftRegistrations'
		]),
		onPageChange(pagination) {
			return this.getDashboard(pagination)
		},
		async getDashboard(pagination) {
			const { page, limit } = pagination
			return this.getShiftDashboard({
				page,
				limit
			})
		},
		getTimezone(locationId) {
			return _.find(this.locationList, {
				id: locationId
			})?.timezone
		},
		getShiftDate(shiftStartAt, locationId) {
			const timezone = this.getTimezone(locationId)
			return moment.utc(shiftStartAt).tz(timezone).format('MMMM Do, YYYY')
		},
		getShiftTime(shiftStartAt, shiftEndAt, locationId) {
			const timezone = this.getTimezone(locationId)
			const startTime = moment
				.utc(shiftStartAt)
				.tz(timezone)
				.format('HH:mm')
			const endTime = moment
				.utc(shiftEndAt)
				.add(1, 'seconds')
				.tz(timezone)
				.format('HH:mm')

			return `${startTime} - ${endTime}`
		}
	}
}
</script>

<template>
	<layout title="Shift Dashboard">
		<form-filter />
		<common-table
			:selected-rows.sync="selectedShiftRegistrations"
			:columns="columns"
			:data="data"
			:total-page="totalPage"
			:on-page-change="onPageChange"
			:current-page="filter.page"
			virtual-scroll
			binary-state-sort
			@selection="updateSelectedShiftRegistrations"
		>
			<template #district="{ row }">
				<div v-if="!row.districts || row.districts.length === 0">
					N/A
				</div>
				<div v-else>
					<div v-for="district in row.districts" :key="district">
						{{ district }}
					</div>
				</div>
			</template>
			<template #actions="{ row }">
				<span class="q-gutter-x-xs">
					<q-btn
						v-if="row.status !== 'APPROVED'"
						no-caps
						color="primary"
						label="Approve"
						padding="5px sm"
						@click="
							approveShiftRegistrations({
								shiftRegistrations: [row],
								status: 'APPROVED'
							})
						"
					/>
					<q-btn
						v-if="row.status !== 'REJECTED'"
						no-caps
						color="primary"
						label="Reject"
						padding="5px sm"
						@click="
							approveShiftRegistrations({
								shiftRegistrations: [row],
								status: 'REJECTED'
							})
						"
					/>
				</span>
			</template>
		</common-table>
	</layout>
</template>
