<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DateInput from '@/components/DateInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import CommonForm from '@/components/CommonForm.vue'
import { initialFilter } from '@/store/modules/shiftDashboard'
import TooltipButton from '@/components/TooltipButton.vue'
import _ from 'lodash'
import StatusBlock from './components/StatusBlock.vue'

export default {
	name: 'FormFilter',
	components: {
		DateInput,
		FormSelect,
		CommonForm,
		TooltipButton,
		StatusBlock
	},
	data() {
		return {
			filter: { ...initialFilter },
			statusOptions: ['APPROVED', 'PENDING', 'REJECTED']
		}
	},
	computed: {
		...mapState('shiftDashboard', [
			'selectedShiftRegistrations',
			'locationList',
			'statusCount'
		]),
		...mapGetters('shiftDashboard', ['liftedFilter', 'locationIdList']),
		canApplyFilter() {
			const { date, locationId } = this.filter
			return date && locationId
		},
		shiftList() {
			return _.chain(this.locationList)
				.find({ id: this.filter.locationId })
				.get('shiftTimeRanges', [])
				.map(({ startAt, endAt }) => ({
					label: `${startAt} - ${endAt}`,
					value: JSON.stringify({
						startAt,
						endAt
					})
				}))
				.value()
		}
	},
	async created() {
		this.getLocationList()
		this.resetFilter()
		this.syncFilter()
	},
	methods: {
		...mapActions('shiftDashboard', [
			'getShiftDashboard',
			'resetFilter',
			'approveShiftRegistrations',
			'getLocationList'
		]),
		async reset() {
			await this.resetFilter()
			this.syncFilter()
			await this.getShiftDashboard(this.filter)
		},
		submit() {
			return this.getShiftDashboard(this.filter)
		},
		syncFilter() {
			this.filter = { ...this.liftedFilter }
		}
	}
}
</script>

<template>
	<div>
		<common-form
			:reset="reset"
			:submit="submit"
			:disable-btn="!canApplyFilter"
			layout="vertical"
		>
			<div class="shift-dashboard-filter">
				<div>
					<date-input
						v-model="filter.date"
						label="Date"
						picker-format="YYYY-MM-DD"
						jquery
					/>
				</div>
				<div>
					<form-select
						v-model="filter.locationId"
						:options="locationIdList || []"
						label="Location"
					/>
					<form-select
						v-model="filter.shifts"
						label="Shifts (optional)"
						:options="shiftList || []"
						nullable
					/>
					<form-select
						v-model="filter.status"
						:options="statusOptions"
						label="Status (optional)"
						nullable
					/>
				</div>
			</div>
			<template #extra-buttons>
				<div class="q-ml-xl">Action:</div>
				<tooltip-button
					:tooltips="[
						'At least one shift registrations must be selected'
					]"
					:disable="selectedShiftRegistrations.length === 0"
					color="primary"
					label="Approve driver(s)"
					@click="
						approveShiftRegistrations({
							shiftRegistrations: selectedShiftRegistrations,
							status: 'APPROVED'
						})
					"
				/>
			</template>
		</common-form>
		<div>
			<status-block :count="statusCount.APPROVED">Approved</status-block>
			<status-block :count="statusCount.PENDING">Pending</status-block>
			<status-block :count="statusCount.REJECTED">Rejected</status-block>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.shift-dashboard-filter {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;

	> div {
		display: flex;
		flex-wrap: wrap;
		grid-gap: 10px;
	}
}
</style>
