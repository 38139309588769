var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"title":"Shift Dashboard"}},[_c('form-filter'),_c('common-table',{attrs:{"selected-rows":_vm.selectedShiftRegistrations,"columns":_vm.columns,"data":_vm.data,"total-page":_vm.totalPage,"on-page-change":_vm.onPageChange,"current-page":_vm.filter.page,"virtual-scroll":"","binary-state-sort":""},on:{"update:selectedRows":function($event){_vm.selectedShiftRegistrations=$event},"update:selected-rows":function($event){_vm.selectedShiftRegistrations=$event},"selection":_vm.updateSelectedShiftRegistrations},scopedSlots:_vm._u([{key:"district",fn:function({ row }){return [(!row.districts || row.districts.length === 0)?_c('div',[_vm._v(" N/A ")]):_c('div',_vm._l((row.districts),function(district){return _c('div',{key:district},[_vm._v(" "+_vm._s(district)+" ")])}),0)]}},{key:"actions",fn:function({ row }){return [_c('span',{staticClass:"q-gutter-x-xs"},[(row.status !== 'APPROVED')?_c('q-btn',{attrs:{"no-caps":"","color":"primary","label":"Approve","padding":"5px sm"},on:{"click":function($event){return _vm.approveShiftRegistrations({
							shiftRegistrations: [row],
							status: 'APPROVED'
						})}}}):_vm._e(),(row.status !== 'REJECTED')?_c('q-btn',{attrs:{"no-caps":"","color":"primary","label":"Reject","padding":"5px sm"},on:{"click":function($event){return _vm.approveShiftRegistrations({
							shiftRegistrations: [row],
							status: 'REJECTED'
						})}}}):_vm._e()],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }